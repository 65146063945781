import * as angular from "angular";
import * as moment from "moment";
import { when, reaction, autorun } from "mobx";
import { $timeout, $compile, $sce, $window } from "ngimport";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import { USER_ROLES } from "Constants/UserRoles";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import SamskipResource from "SamskipResource";
import {
  UserService,
  SamskipNotify,
  TranslationService,
  ContainerService,
  WarehouseService,
  FinanceService,
  ShipmentService,
  DomesticService,
} from "Services";
import { UtilityFunctions } from "Utilities";
import { $uiRouter } from "Bootstrap/angular";

declare var GlobalConfig: GlobalConfig;
declare var $: any;

function homeController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;

  const self = this;

  const companyChanged = reaction(
    () => UserService.getSelectedCompanyObservable(),
    () => {
      const currentUser = UserService.getUserProfile();

      if (currentUser) {
        $timeout(() => {
          loadData();
        });
      }
    },
    { fireImmediately: true }
  );

  const reactionFn = autorun(() => {
    const lang = TranslationService.getSelectedLanguage();
  });

  $scope.$on("$destroy", () => {
    reactionFn();
    companyChanged();
  });

  $scope.totalQuotesCount = 0;
  $scope.shipments = [];
  $scope.transports = [];
  $scope.finances = {};
  $scope.center = 0;
  $scope.tCenter = 0;

  self.cardRotated = {
    freight: false,
    domestic: false,
    containers: false,
    warehouse: false,
    finance: false,
  };

  UtilityFunctions.smallSpinnerPos("totalQuotesCount", "50%", "17%", "#000");
  UtilityFunctions.smallSpinnerPos("shipmentsInWeek", "50%", "50%", "#000");
  UtilityFunctions.smallSpinnerPos("shipmentsReady", "50%", "83%", "#000");
  UtilityFunctions.smallSpinnerPos("transportsInWeek", "50%", "25%", "#000");
  UtilityFunctions.smallSpinnerPos("transportsActive", "50%", "75%", "#000");
  UtilityFunctions.smallSpinnerPos("unPaidTotal", "50%", "25%", "#000");
  UtilityFunctions.smallSpinnerPos("pastDueDateTotal", "50%", "75%", "#000");
  UtilityFunctions.smallSpinnerPos(
    "containersRegistered",
    "50%",
    "25%",
    "#000"
  );
  UtilityFunctions.smallSpinnerPos("containersReady", "50%", "75%", "#000");
  UtilityFunctions.smallSpinnerPos("ReceptionsProgress", "50%", "50%", "#000");
  UtilityFunctions.smallSpinnerPos("OrdersProgress", "50%", "75%", "#000");

  function getQuotesTotal() {
    const currentUser = UserService.getUserProfile();

    if (!currentUser) return;

    $(".loadedQuotes").fadeTo(200, 0);
    $(".loaderQuotes").show(400);

    ShipmentService.getQuoteCount().then(
      (data: QuoteCount) => {
        $scope.totalQuotesCount = data.TotalQuotes;
        $(".loaderQuotes").hide(200);
        $(".loadedQuotes").fadeTo(400, 1);
      },
      (data: any) => {
        $(".loaderQuotes").hide(200);
        $scope.quotesCount = {};
        SamskipNotify.displayError(data.data);
      }
    );
  }

  function getBookingTotal() {
    const currentUser = UserService.getUserProfile();

    if (!currentUser) return;

    const selectedCompany = UserService.getSelectedCompany();
    $(".loadedShipments").fadeTo(200, 0);
    $(".loaderShipments").show(400);

    const minus18Weeks = moment()
      .startOf("isoWeek")
      .subtract(18, "weeks")
      .format("YYYY-MM-DD");
    const plus6Weeks = moment()
      .endOf("isoWeek")
      .add(6, "weeks")
      .format("YYYY-MM-DD");

    ShipmentService.companyShipments(
      minus18Weeks,
      plus6Weeks,
      selectedCompany.PartnerCode
    ).then(
      (data: any) => {
        $scope.shipments = data;
        $scope.center = Math.round($scope.shipments.Shipments.length / 2);
        $scope.currentWeek = moment().isoWeek();

        $(".loaderShipments").hide(200);
        $(".loadedShipments").fadeTo(400, 1);

        $timeout(displaySeatransport);
      },
      (data: any) => {
        $(".loaderShipments").hide(200);
        $scope.shipments = {};
        SamskipNotify.displayError(data.data);
      }
    );
  }

  function getContainersTotal() {
    const currentUser = UserService.getUserProfile();

    if (!currentUser) return;

    const selectedCompany = UserService.getSelectedCompany();
    $(".loadedContainers").fadeTo(200, 0);
    $(".loaderContainers").show(400);

    ContainerService.getContainerTileInfo(selectedCompany.PartnerCode).then(
      (data: any) => {
        $scope.containers = data;
        $(".loaderContainers").hide(200);
        $(".loadedContainers").fadeTo(400, 1);
        $scope.$digest();
      },
      () => {
        $(".loaderContainers").hide(200);
        $scope.containers = {};
      }
    );
  }

  function getTransportsTotal() {
    const currentUser = UserService.getUserProfile();

    if (!currentUser) return;

    const selectedCompany = UserService.getSelectedCompany();
    $(".loadedTransport").fadeTo(200, 0);
    $(".loaderTransport").show(400);

    if (!selectedCompany.CompanyRegNo) {
      $(".loaderTransport").hide(200);
      SamskipNotify.displayError("TEXT_NOCOMPANYREGNO");
      $scope.transports = {};
      return;
    }
    const minus4Weeks = moment()
      .startOf("isoWeek")
      .subtract(4, "weeks")
      .format("YYYY-MM-DD");
    const plus2Weeks = moment()
      .endOf("isoWeek")
      .add(2, "weeks")
      .format("YYYY-MM-DD");

    DomesticService.companyTransports(
      minus4Weeks,
      plus2Weeks,
      selectedCompany.CompanyRegNo
    ).then(
      (data: DomesticTransportsTileVM) => {
        $scope.transports = data;
        $scope.tCenter = Math.round($scope.transports.Transports.length / 2);
        $scope.domesticActiveLink =
          data.TransportsActiveDateFrom && data.TransportsActiveDateTo;
        $scope.currentWeek = moment().isoWeek();
        $(".loaderTransport").hide(200);
        $(".loadedTransport").fadeTo(400, 1);

        $timeout(displayDomestictransport);
      },
      () => {
        $(".loaderTransport").hide(200);
        $scope.transports = {};
      }
    );
  }

  function getWarehouseTotal() {
    const currentUser = UserService.getUserProfile();
    const currentCompany = UserService.getSelectedCompany();

    if (!currentUser) return;

    $(".loadedWarehouse").fadeTo(200, 0);
    $(".Warehouseloader").show(400);

    WarehouseService.warehouseProgress(
      currentUser.User.ID,
      currentCompany.SapID
    ).then(
      (data: any) => {
        $(".Warehouseloader").hide(200);
        $(".loadedWarehouse").fadeTo(400, 1);

        $scope.warehouse = data;
      },
      () => {
        $(".Warehouseloader").hide(200);
        $(".loadedWarehouse").fadeTo(400, 1);
        $scope.warehouse = {};
      }
    );
  }

  $scope.changeDate = function changeDate(
    date: Date,
    dateFormat: string
  ): string {
    return moment(date, "YYYY-MM-DD").isValid()
      ? moment(date, "YYYY-MM-DD").format(dateFormat)
      : "";
  };

  $scope.changeBlogDate = function changeBlogDate(
    date: Date,
    dateFormat: string
  ): string {
    return moment(date).format(dateFormat);
  };

  function rewritePath(path: string) {
    const newPathParts = path.split("preview/");
    const newPath = newPathParts[0] + newPathParts[1];

    return newPath;
  }

  function clearHTML(textString: string) {
    const result = $sce.trustAsHtml(textString);
    return result.length > 160 ? `${result.substring(0, 160)}...` : result;
  }

  $scope.searchShipments = function searchShipments() {
    if ($scope.ShipmentsQuery) {
      $state.go("shipments_search", {
        query: $scope.ShipmentsQuery.toUpperCase(),
      });
    }
  };

  $scope.searchTransports = function searchTransports() {
    if ($scope.TransportsQuery != null) {
      $state.go("domestic_search", {
        query: $scope.TransportsQuery,
      });
    }
  };

  $scope.searchContainers = function searchContainers() {
    if ($scope.ContainersQuery) {
      $state.go("containers_search", {
        query: $scope.ContainersQuery.toUpperCase(),
      });
    }
  };

  $scope.searchFinance = function searchFinance() {
    if ($scope.FinanceQuery) {
      $state.go("finance_search", {
        query: $scope.FinanceQuery.toUpperCase(),
      });
    }
  };

  $scope.RotateCardClick = function RotateCardClick(card: string) {
    self.cardRotated[card] = !self.cardRotated[card];
    if (!self.cardRotated[card]) {
      if (card === "freight") {
        displaySeatransport();
      } else if (card === "domestic") {
        displayDomestictransport();
      }
    }
  };

  $scope.goToQuotes = () => {
    $window.open(`${GlobalConfig.ENV.NewServiceWeb}/shipments/quotes`);
  };

  $scope.goToCreateQuote = () => {
    $window.open(`${GlobalConfig.ENV.NewServiceWeb}/shipments/quotes/request`);
  };

  $scope.goToCreateDomestic = () => {
    $window.open(`${GlobalConfig.ENV.NewServiceWeb}/domestic/create`);
  };

  /* INTRO START */

  $scope.tutorialSteps = [
    {
      element: "#freight-card.js-Card-wrapper",
      title: "TUTOR_HEADING",
      content: "TUTOR_MYSITE",
      position: "bottom",
    },
    {
      element: "#domestic-card.js-Card-wrapper",
      title: "TUTOR_HEADING",
      content: "TUTOR_MYSITE",
      position: "bottom",
    },
    {
      element: "#containers-card.js-Card-wrapper",
      title: "TUTOR_HEADING",
      content: "TUTOR_MYSITE",
      position: "bottom",
    },
    {
      element: "#warehouse-card.js-Card-wrapper",
      title: "TUTOR_HEADING",
      content: "TUTOR_MYSITE",
      position: "bottom",
    },
  ];

  /* INTRO END */

  function loadData() {
    if (UserService.isAuthorized(USER_ROLES.SHIP)) {
      getBookingTotal();
      getContainersTotal();
      getQuotesTotal();
    } else {
      $("#freight-card .CardInfo").show();
      $("#freight-card .js-CardMain").hide();

      $("#containers-card .CardInfo").show();
      $("#containers-card .js-CardMain").hide();
    }

    if (UserService.isAuthorized(USER_ROLES.DOM)) {
      getTransportsTotal();
    } else {
      $("#domestic-card .CardInfo").show();
      $("#domestic-card .js-CardMain").hide();
    }

    if (UserService.isAuthorized(USER_ROLES.WHOUSE)) {
      getWarehouseTotal();
    } else {
      $("#warehouse-card .CardInfo").show();
      $("#warehouse-card .js-CardMain").hide();
    }

    $(".Home > .row > .col-md-6").each((index: number) => {
      const elm = $(this);
      elm.removeClass("no-left").removeClass("no-right");
      if (index % 2 === 0) {
        elm.addClass("no-left");
      } else {
        elm.addClass("no-right");
      }
    });

    $compile(angular.element(".CardInfo"))($scope);
  }
}

function displaySeatransport() {
  const elm = $("#freight-card");

  const shipment = elm.find(".shipmentList");
  let center = parseFloat(shipment.attr("data-center"));
  center = elm.find(".current").parent().index();
  shipment.attr("data-center", center);
  shipment.find(`li:eq(${center}) a`).addClass("center");
  shipment
    .find(
      `li:eq(${center}),li:eq(${center + 1}),li:eq(${center + 2}),li:eq(\
        ${center - 1}),li:eq(${center - 2})`
    )
    .css("display", "inline-block");

  $(".shipmentLeftArrow").on("click", () => {
    center = shipment.find(".center").parent().index();
    if (center > 2) {
      shipmentShiftLeft(center);
    }
  });
  $(".shipmentRightArrow").on("click", () => {
    center = shipment.find(".center").parent().index();
    if (center < shipment.attr("data-max")) {
      shipmentShiftRight(center);
    }
  });
}

/*
    functions for listitem shift in sea transport
 */
function shipmentShiftLeft(ind: number) {
  const shipment = $(".shipmentList");

  shipment.find(`li:eq(${ind + 2})`).fadeTo(400, 0, function cb() {
    $(this).css({
      display: "none",
    });
  });
  shipment.find(`li:eq(${ind}) a`).removeClass("center");
  shipment.find(`li:eq(${ind - 1}) a`).addClass("center");
  shipment
    .find(`li:eq(${ind - 3})`)
    .delay(400)
    .css({
      width: "0%",
      opacity: 0,
      display: "inline-block",
    })
    .animate(
      {
        width: "20%",
      },
      400,
      function cb() {
        $(this).fadeTo(400, 1);
      }
    );

  shipment.attr("data-center", ind - 1);
}
function shipmentShiftRight(ind: number) {
  const shipment = $(".shipmentList");

  shipment.find(`li:eq(${ind - 2})`).fadeTo(400, 0, function hide() {
    $(this).animate(
      {
        width: "0%",
      },
      400,
      function cb() {
        $(this).css({
          width: "20%",
          display: "none",
        });
      }
    );
  });
  shipment.find(`li:eq(${ind}) a`).removeClass("center");
  shipment.find(`li:eq(${ind + 1}) a`).addClass("center");
  shipment
    .find(`li:eq(${ind + 3})`)
    .delay(400)
    .css({
      display: "inline-block",
      opacity: 0,
    })
    .fadeTo(400, 1);
  shipment.attr("data-center", ind + 1);
}

// Domestic
function displayDomestictransport() {
  const elm = $("#domestic-card");

  const transport = elm.find(".transportList");
  let center = parseFloat(transport.attr("data-center"));
  center = elm.find(".current").parent().index();
  transport.attr("data-center", center);
  transport.find(`li:eq(${center}) a`).addClass("center");
  transport
    .find(`li:eq(${center}),li:eq(${center + 1}),li:eq(${center - 1})`)
    .css("display", "inline-block");

  $(".transportLeftArrow").on("click", () => {
    center = transport.find(".center").parent().index();
    if (center > 1) {
      transportShiftLeft(center);
    }
  });
  $(".transportRightArrow").on("click", () => {
    center = transport.find(".center").parent().index();
    if (center < transport.attr("data-max")) {
      transportShiftRight(center);
    }
  });
}

/*
    functions for listitem shift in sea transport
 */
function transportShiftLeft(ind: number) {
  const transport = $(".transportList");

  transport.find(`li:eq(${ind + 1})`).fadeTo(400, 0, function fadeTo() {
    $(this).css({
      display: "none",
    });
  });
  transport.find(`li:eq(${ind}) a`).removeClass("center");
  transport.find(`li:eq(${ind - 1}) a`).addClass("center");
  transport
    .find(`li:eq(${ind - 2})`)
    .delay(400)
    .css({
      width: "0%",
      opacity: 0,
      display: "inline-block",
    })
    .animate(
      {
        width: "33%",
      },
      400,
      function cb() {
        $(this).fadeTo(400, 1);
      }
    );

  transport.attr("data-center", ind - 1);
}
function transportShiftRight(ind: number) {
  const transport = $(".transportList");

  transport.find(`li:eq(${ind - 1})`).fadeTo(400, 0, function fadeTo() {
    $(this).animate(
      {
        width: "0%",
      },
      400,
      function cb() {
        $(this).css({
          width: "33%",
          display: "none",
        });
      }
    );
  });
  transport.find(`li:eq(${ind}) a`).removeClass("center");
  transport.find(`li:eq(${ind + 1}) a`).addClass("center");
  transport
    .find(`li:eq(${ind + 2})`)
    .delay(400)
    .css({
      display: "inline-block",
      opacity: 0,
    })
    .fadeTo(400, 1);
  transport.attr("data-center", ind + 1);
}

homeController.$inject = ["$scope", "$transition$"];
export default homeController;
