import React, { useState, useEffect } from "react";
import { TranslationService, languages, VoyageService } from "Services";
import { reaction } from "mobx";
import styles from "./index.less";
declare var GlobalConfig: GlobalConfig;

const vesselmapUrl = GlobalConfig.ENV.VesselMap;
const iframeStyle = {
  border: "none",
  height: "100%",
  width: "100%",
};

export default () => {
  const [language, setLanguage] = useState(
    TranslationService.getSelectedLanguage()
  );

  useEffect(() => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => setLanguage(TranslationService.getSelectedLanguage())
    );
  }, []);

  return (
    <div className={`col-md-6 no-left Card ${styles.card}`}>
      <div className="js-Card-wrapper" id="shippingSchedule-card">
        <div className="CardInfo CardInfo-shippingSchedule">
          <div className="CardInfo-overlay">
            <div className="CardInfo-textWrapper">
              <h2 className="CardInfo-textHeader">
                {languages("MENU_SHIPPING_SCHEDULE")}
              </h2>
            </div>
          </div>
        </div>
        <div className="js-CardMain">
          <div className="CardMain-face js-CardMain-front CardMain-front-shippingSchedule">
            <h3 className="CardMain-front-head header-map-card">
              {languages("MENU_SHIPPING_SCHEDULE")}
            </h3>
            {
              <a
                className="map-link"
                target="_blank"
                href="https://www.samskip.is/siglingaaaetlanir/rauntimakort/"
              ></a>
            }
            {<iframe src={vesselmapUrl} style={iframeStyle} scrolling="no" />}
          </div>
        </div>
      </div>
    </div>
  );
};
