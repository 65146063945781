import { reaction } from "mobx";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Grid, GridRow as Row, GridColumn as Column } from "semantic-ui-react";
import { languages, TranslationService, VoyageService } from "Services";
import styles from "./index.less";
import { Spinner } from "Component";

export default (props: any) => {
  const [voyages, setVoyages] = useState([] as Voyage[]);
  const [language, setLanguage] = useState(
    TranslationService.getSelectedLanguage()
  );

  useEffect(() => {
    VoyageService.getCurrentVoyages().then((data) => {
      setVoyages(data);
    });

    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => setLanguage(TranslationService.getSelectedLanguage())
    );
  }, []);

  const getCurrentVisit = (visits: Visit[]) => {
    return visits.find(
      (visit) =>
        (!!visit.ATADate && !visit.ATDDate && !!visit.ETDDate) ||
        (!visit.ATADate && !!visit.ETADate)
    );
  };

  const formatTime = (dateTime: any, timeZoneDiff: number) => {
    const m = moment(dateTime);
    const startOfHourCheck = moment(dateTime).startOf("hour");
    const halfHourCheck = moment(dateTime).startOf("hour").add(30, "minutes");

    if (m.isSame(startOfHourCheck)) {
      return m.add(timeZoneDiff, "hours").format("HH:mm");
    }
    if (m.isBetween(startOfHourCheck, halfHourCheck)) {
      return m.startOf("hour").add(timeZoneDiff, "hours").format("HH:mm");
    }
    if (m.isSameOrAfter(halfHourCheck)) {
      return m
        .endOf("hour")
        .add(timeZoneDiff, "hours")
        .add(1, "minutes")
        .format("HH:mm");
    }
  };

  const renderRow = (voyage: Voyage, index: number) => {
    const visit = getCurrentVisit(voyage.Visits);
    const shipColor =
      visit && visit.ATADate
        ? moment()
            .tz("Atlantic/Reykjavik")
            .isAfter(
              formatTime(
                moment(visit.ETDDate ? visit.ETDDate : "").add(8, "hours"),
                visit.TimeZoneDiff
              )
            )
          ? "svgRedVessel"
          : "svgGreenVessel"
        : "svgBlueVessel";
    return (
      <Row key={index}>
        <Column width={2}>
          <a
            href="https://www.samskip.is/siglingaaaetlanir/raunsiglingar/aaetlun-naestu-vikna/"
            target="_blank"
          >
            {voyage.Reference}
          </a>
        </Column>
        <Column width={2}>
          <span className={shipColor}></span>
        </Column>
        <Column width={4}>
          {voyage.VesselObj.Name.replace("Samskip ", "")}
        </Column>
        <Column width={4}>
          {visit &&
            `${visit.PortCode.slice(2)}, ${
              visit.ATADate ? "ETD:" : "ETA:"
            } @${formatTime(
              visit.ATADate ? visit.ETDDate : visit.ETADate,
              visit.TimeZoneDiff
            )}`}
        </Column>
        <Column width={4}>{languages(`ROUTE_${voyage.ServiceArea}`)}</Column>
      </Row>
    );
  };

  return (
    <>
      {<Spinner isHidden={voyages.length > 0} className="" />}
      <Grid
        className={`${styles.voyages} ${voyages.length > 0 ? "fade-in" : ""}`}
      >
        {voyages.map((voyage: Voyage, index: number) =>
          renderRow(voyage, index)
        )}
      </Grid>
    </>
  );
};
