import * as angular from "angular";
import { UIRouter } from "@uirouter/core";
import HomeController from "Home/HomeController";
import * as homeTemplate from "Home/partials/home.html";
import { react2angular } from "react2angular";

// Controllers
import VoyageScheduleWrapper from "./voyageScheduleWrapper/index";
import ShippingScheduleCard from "./shippingScheduleCard/index";

export const MAIN_STATE: string = "home";

class Home {
  constructor($uiRouter: UIRouter) {
    const routes: any[] = [
      {
        name: `${MAIN_STATE}`,
        url: "/",
        parent: "masterPage",
        template: `${homeTemplate}`,
        controller: HomeController,
        controllerAs: "$ctrl",
        data: {
          roles: []
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

Home.$inject = ["$uiRouter"];

angular
  .module("serviceWebApp")
  .run(Home)
  .component("voyageschedulewrapper", react2angular(VoyageScheduleWrapper, []))
  .component("shippingScheduleCard", react2angular(ShippingScheduleCard, []));
